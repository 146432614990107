<template>
  <div id="app">
    <Particles></Particles>
    <div class="content">
      <h1 class="title">FREDERICK</h1>
      <p class="desc">internet addict.</p>
      <ul class="links">
        <li v-for="link in links" :key="link.name">
          <a :target="link.newTab ? '_blank' : false" :href="link.url">{{ link.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Particles from '@/components/Particles.vue';
  import { links } from '@/data/links';

  export default {
    name: 'App',
    components: {
      Particles,
    },
    data() {
      return {
        links,
      };
    },
    mounted() {
      const ul = document.querySelector('ul');

      for (let i = ul.children.length; i >= 0; i--) {
        // randomize links order - just 4 teh lulz
        ul.appendChild(ul.children[Math.random() * i | 0]);
      }
    },
  };
</script>

<style lang="scss">
  @import '@/style/reset';
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;500&display=swap');

  #app {
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(25deg, #111865, #519eb5);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  }

  .content {
    left: 15%;
    top: 20%;
    width: 70%;
    position: relative;
  }

  .title {
    color: white;
    font-size: 4vw;
    font-weight: 500;
  }

  .desc {
    font-size: 4vw;
    color: rgba(255, 255, 255, 1);
    font-weight: 200;
    margin-bottom: 0.5em;
  }

  .links li {
    list-style-type: none;
    float: left;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 100;
    font-size: 1.4em;

    ~ li {
      margin-left: 1vw;
    }
  }

  a {
    color: rgba(255, 255, 255, 0.8);
    text-transform: lowercase;
    text-decoration: none;

    :hover {
      color: rgba(255, 255, 255, 1);
    }
  }
</style>
