// eslint-disable-next-line import/prefer-default-export
export const links = [
  {
    name: 'github',
    url: 'https://github.com/MrBurrBurr',
    newTab: true,
  },
  {
    name: 'steam',
    url: 'https://steamcommunity.com/profiles/76561197988110398',
    newTab: true,
  },
  {
    name: 'todo',
    url: 'https://todo.frederick.win',
    newTab: false,
  },
  {
    name: '2048',
    url: 'https://2048.frederick.win',
    newTab: false,
  },
];
