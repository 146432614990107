<template>
  <vue-particles
      color="#dedede"
      :particleOpacity="0.6"
      :particlesNumber="70"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="false"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="1"
      :hoverEffect="false"
      hoverMode="grab"
      :clickEffect="false"
      clickMode="push"
  ></vue-particles>
</template>

<script>
export default {
  name: 'Particles',
};
</script>

<style lang="scss" scoped>
#particles-js {
  position: absolute;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  z-index: 0;
}
</style>
